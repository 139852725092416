/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useContext, useEffect, useState } from 'react'
import { defaultStatus } from '../../../constants'
import { formateDate } from '../../../services/dateHandless'
import { authContext } from '../../../context/authContext'
import { requestContext } from '../../../context/requestContext'
import groupBy from '../../../services/groupBy'
import ListCheckinspector from '../../list/checkinspector'
import ImageItem from '../../list/img'

const RendermodalEvent = ({ handleClose, itemsEvent, handleEditItem, title, handleAddEvent }) => {
	const [items, setItems] = useState(null)
	const { getItemCheckInspection, check_inspection, setCheckInspection } = useContext(requestContext)
	useEffect(() => {
		setCheckInspection(null)
		const loadGroupItems = () => {
			const newItems = itemsEvent.map(item => {
				const { pedido_venda, pedido_venda_local, pedido_venda_servico } = item
				return {
					ped_venda_cod: pedido_venda.ped_venda_cod,
					pedido_venda,
					pedido_venda_local,
					pedido_venda_servico
				}
			})
			const group = groupBy(newItems, 'ped_venda_cod')
			setItems(group)
		}
		if (Array.isArray(itemsEvent)) {
			loadGroupItems()
		}
	}, [itemsEvent])
	const { user } = useContext(authContext)
	const { postItemTimelineOversight } = useContext(requestContext)
	const handleApproved = (item) => {
		const { ped_venda_cod, conc_nome } = item.pedido_venda
		const confirm = window.confirm(`Você deseja mesmo aprovar esse cronograma da ${conc_nome} ?`)
		if (!confirm) {
			return
		}
		postItemTimelineOversight({
			id: ped_venda_cod,
			approved: ped_venda_cod,
			pv_servico: item.pedido_venda_servico
		}, () => {
			handleClose()
		})
	}
	const RenderNumberTimeline = ({ ped_venda_cod }) => {
		if (!ped_venda_cod || !Array.isArray(itemsEvent) || !items) {
			return (<div> </div>)
		}
		const findItem = itemsEvent.find(item => {
			if (item.pedido_venda.ped_venda_cod === parseInt(ped_venda_cod)) {
				return item
			}
			return false
		})
		if (!findItem) {
			return (<div> </div>)
		}
		return (
			<h3 className="card-title col-12">
                Cronograma n° {ped_venda_cod} da concesionária {findItem.pedido_venda?.conc_nome}
			</h3>
		)
	}
	const RenderButtonEdit = ({ ped_venda_cod }) => {
		if (!ped_venda_cod || !Array.isArray(itemsEvent) || !items) {
			return (<div> </div>)
		}
		const findItem = itemsEvent.find(item => {
			if (item.pedido_venda.ped_venda_cod === parseInt(ped_venda_cod)) {
				return item
			}
			return false
		})
		if (!findItem) {
			return <></>
		}
		return (
			<div className="col-4">
				<button
					onClick={() => handleEditItem({ ...findItem })}
					type="button" className="btn btn-danger btn-sm">
					<i className="fa fa-edit"></i>  Editar cronograma de {findItem.pedido_venda.conc_nome}
				</button>
			</div>
		)

	}
	const RenderButtonApproved = ({ ped_venda_cod }) => {
		if (!ped_venda_cod || !Array.isArray(itemsEvent) || !items) {
			return <></>
		}
		const findItem = itemsEvent.find(item => item.pedido_venda.ped_venda_cod === parseInt(ped_venda_cod))
		if (!findItem) {
			return (
				<div>sss</div>
			)
		}
		if (findItem.pedido_venda.st_desc === defaultStatus.PROGRAMADO_PEDENTE_APROVACAO && user?.set_nivel === 0) {
			return (
				<div className="col-4 mb-3">
					<button onClick={() => handleApproved({
						...findItem
					})} className="btn btn-success btn-sm">
						<i className="fa fa-check"></i> Aprovar cronograma
					</button>
				</div>
			)
		}
		return (
			<div>sss</div>
		)
	}
	const handleViewCheckInspection = (ped_venda_cod) => {
		getItemCheckInspection(ped_venda_cod)
	}
	return (
		<div className="card-danger mt-3">
			<div className="card-header">
				<h3 className="card-title">{title}</h3>
				<div className="card-tools">
					<button type="button" className="btn btn-tool" data-card-widget="collapse">
						<i className="fas fa-minus"></i>
					</button>
					<button type="button" className="btn btn-tool" data-toggle="modal" data-target="#concessionaria">
						<i className="fa fa-question-circle"></i>
					</button>
				</div>
			</div>
			{items &&
                Object.keys(items).map(element => (<div className="card-body" key={element}>
                	<RenderNumberTimeline ped_venda_cod={element} />
                	{items[element].map(item => (<div key={item.pedido_venda.ped_venda_cod} className="row">
                		{item?.pedido_venda_servico?.length && item?.pedido_venda_servico.map((servico, i) => (
                			<div className="row" key={servico.pvs_cod}>
                				{i > 0 && (
                					<hr className="col-12 bg-secondary" />
                				)}
                				<div className="col-4 mb-3">
                					<span className="text-secondary mr-2">
                                        Quantidade:
                					</span>
                					{servico.pvs_qtd}
                				</div>
                				{servico.serv_sub_cat_desc && (
                					<div className="col-sm-6 col-md-8 mb-2">
                						<span className="text-secondary mr-2">
                                            Atividade:
                						</span>
                						{servico?.serv_sub_cat_desc}
                					</div>
                				)}
                				{servico?.serv_desc && (
                					<div className="col-sm-6 col-md-4 mb-2">
                						<span className="text-secondary mr-2">
                                            Tipo atividade:
                						</span>
                						{servico?.serv_desc}
                					</div>
                				)}
                				{servico?.serv_cat_desc && (
                					<div className="col-sm-6 col-md-4 mb-2">
                						<span className="text-secondary mr-2">
                                            Grupo da atividade:
                						</span>
                						{servico?.serv_cat_desc}
                					</div>
                				)}
                				<div className="col-sm-12 col-md-4 mb-3">
                					<span style={{ backgroundColor: item.pedido_venda.st_cor_valor }} className="badge text-center text-light mr-2 p-2">
                						{item.pedido_venda.st_desc}
                					</span>
                				</div>
                				<div className="col-4 mb-3">
                					<span className="text-secondary mr-2">
                                        Iniciar execução dia:
                					</span>
                					{formateDate(`${servico.pvs_prazo_ini}`)}
                				</div>
                				<div className="col-4 mb-3">
                					<span className="text-secondary mr-2">
                                        Terminar execução dia:
                					</span>
                					{formateDate(`${servico.pvs_prazo_fim}`)}
                				</div>
                				{item?.pedido_venda_local?.length >= 1 && item?.pedido_venda_local.filter(local => local.pv_loc_cod === servico.pvs_loc).map(local => (
                					<div className="col-12" key={local.pv_loc_cod}>
                						<div className="mb-3 text-secondary">
                                            Localização:
                						</div>
                						<div className="row">
                							<div className="col-4 mb-3">
                								<span className="text-secondary mr-2">
                                                    Rodovia:
                								</span>
                								{item.pedido_venda.rod_desc}
                							</div>
                							<div className="col-4 mb-3">
                								<span className="text-secondary mr-2">
                                                    Km inicial:
                								</span>
                								{local.pv_loc_km_ini}, {local.pv_loc_km_init_mts}
                							</div>
                							<div className="col-4 mb-3">
                								<span className="text-secondary mr-2">
                                                    Km final:
                								</span>
                								{local.pv_loc_km_fim}, {local.pv_loc_km_fim_mts}
                							</div>
                							<div className="col-4 mb-3">
                								<span className="text-secondary mr-2">
                                                    Observações:
                								</span>
                								{local.pv_loc_obs}
                							</div>
                						</div>
                					</div>
                				))}
                				{Array.isArray(servico.pv_servico_fotos)
                					? servico.pv_servico_fotos.map((photo, i) => (
                						<div className="col-md-4 col-12" key={i}>
                							<ImageItem
                								item={photo}
                								key={i}
                								legend={photo.pvs_loc_foto_legenda}
                								src={photo.pvs_loc_foto_path}
                							/>
                						</div>
                					))
                					: 'Sem imagens'}
                			</div>
                		))}
                	</div>))}
                	<div className="row">
                		{!check_inspection && (
                			<>
                				<RenderButtonApproved ped_venda_cod={element} />
                				<RenderButtonEdit ped_venda_cod={element} />
                			</>
                		)}
                		{Array.isArray(check_inspection?.data) && (
                			<div className="col-12">
                				<ListCheckinspector data={check_inspection} />
                			</div>
                		)}
                		<div className="col-4">
                			{Array.isArray(check_inspection?.data) ? (
                				<button onClick={() => setCheckInspection(null)} className="btn btn-dark">
                					<i className="fa fa-eye"></i> Esconder retornos
                				</button>
                			) : (
                				<button onClick={() => handleViewCheckInspection(element)} className="btn btn-dark">
                					<i className="fa fa-eye"></i> Ver retornos
                				</button>
                			)}
                		</div>
                	</div>
                </div>))}
			<div className="card-footer">
				<button type="button" className="btn btn-secondary mr-3" onClick={handleClose}>Fechar</button>
				{handleAddEvent && (
					<button onClick={handleAddEvent} type="button" className="btn btn-danger" data-dismiss="modal">
						<i className="fa fa-calendar mr-2" aria-hidden="true"></i>
                        Adicionar evento
					</button>
				)}
			</div>
		</div>

	)
}
export default RendermodalEvent