import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import Dealership from '../dealership'
import Highway from '../highway'
import SelectTypeContruction from '../selectTypeContruction'
import { addDots, removeDots } from '../../../services/stringHandless'
import FormMonitoring from './monitoring'
import FormMonitoringProgressConstructionPlan from '../monitoringProgressConstructionPlan'
import { useLocation } from 'react-router-dom'
import { namesScreens } from '../../../constants'
const FormContructions = ({ monitoringProgressItems, setMonitoringProgressItems }) => {
	const { state, onChange, handleForm } = useContext(formContext)
	const location = useLocation()
	const [disabled, setDisabled] = useState(false)
	useEffect(() => {
		const controllDisabledInputs = () => {
			const { pathname } = location
			if (pathname === namesScreens.REPORT_CONSTRUCTION_PLAN) {
				return setDisabled(true)
			}
			return setDisabled(false)
		}
		controllDisabledInputs()
	}, [location])
	const {
		pl_ob_desc_object,
		pl_ob_cod_item } = state
	const onBlur = ({ target: { value } }) => {
		const formated_value = removeDots(value)
		const new_value = addDots({ string: formated_value, padNumber: 3 })
		handleForm({
			pl_ob_cod_item: new_value,
		})
	}
	return (
		<form style={{ padding: '0.5rem', backgroundColor: 'white' }}>
			<div className='row'>
				<div className='col-12'>
					<SelectTypeContruction />
				</div>
				<Dealership />
				<Highway />
				<div className="form-group col-md-6 col-sm-12">
					<label>Nome da Obra ou do objeto:</label>
					<div className="input-group">
						<input
							type="text"
							className={`form-control form-control-border ${!pl_ob_desc_object && 'is-invalid'}`}
							name="pl_ob_desc_object"
							value={pl_ob_desc_object}
							onChange={onChange}
							placeholder='Nome da Obra ou do objeto'
							disabled={disabled}
						/>
					</div>
				</div>
				<div className="form-group col-md-6 col-sm-12">
					<label>Item de serviço:</label>
					<div className="input-group">
						<input
							type="text"
							className={`form-control form-control-border ${!pl_ob_cod_item && 'is-invalid'}`}
							name="pl_ob_cod_item"
							value={pl_ob_cod_item}
							onChange={onChange}
							onBlur={onBlur}
							placeholder='Item de serviço'
							disabled={disabled}
						/>
					</div>
				</div>
				<div className="card card-danger">
					<div className="card-header">
						<h3 className="card-title">Cronograma da obra</h3>
						<div className="card-tools">
							<button
								type="button"
								className="btn btn-tool"
								data-card-widget="collapse"
							>
								<i className="fas fa-minus"></i>
							</button>
							<button
								type="button"
								className="btn btn-tool"
								data-toggle="modal"
								data-target="#concessionaria"
							>
								<i className="fa fa-question-circle"></i>
							</button>
						</div>
					</div>
					<div className="card-body">
						<FormMonitoring />
					</div>
					<div className='card card-danger'>
						<div className="card-header">
							<h3 className="card-title">Cronograma de Acompanhamento da Obra</h3>
							<div className="card-tools">
								<button
									type="button"
									className="btn btn-tool"
									data-card-widget="collapse"
								>
									<i className="fas fa-minus"></i>
								</button>
								<button
									type="button"
									className="btn btn-tool"
									data-toggle="modal"
									data-target="#monitoring"
								>
									<i className="fa fa-question-circle"></i>
								</button>
							</div>
						</div>
						<div className='card-body'>
							<FormMonitoringProgressConstructionPlan
								monitoringProgressItems={monitoringProgressItems}
								setMonitoringProgressItems={setMonitoringProgressItems} />
						</div>
					</div>
				</div>
			</div>
		</form >
	)
}
export default FormContructions
