import React, { useContext } from "react";
import { formContext } from "../../../../context/formContext";
import { formateValidatedDate } from "../../../../services/dateHandless";
const FormMonitoring = () => {
    const { state, onChange, handleForm } = useContext(formContext)
    const {
        pl_ob_data_program_ini,
        pl_ob_data_program_fim,
        pl_ob_data_ana_ini,
        pl_ob_data_ana_fim,
        pl_ob_data_prev_ini,
        pl_ob_data_prev_fim,
    } = state
    const onBlurPrevDate = ({ target: { value, name } }) => {
        if (!value) {
            return
        }
        handleForm({
            [name]: value
        })
    }
    return (
        <div className="row">
            <div className="col-12 text-secondary mt-3">Analise</div>
            <div className="form-group col-6">
                <label>Obra analise para começar:</label>
                <div className="input-group">
                    <input
                        type="date"
                        className={`form-control form-control-border ${!pl_ob_data_ana_ini && 'is-invalid'
                            }`}
                        name="pl_ob_data_ana_ini"
                        value={pl_ob_data_ana_ini}
                        onChange={onChange}
                        placeholder="_/__/____"
                    />
                </div>
            </div>
            <div className="form-group col-6">
                <label>Obra analise para terminar:</label>
                <div className="input-group">
                    <input
                        type="date"
                        className={`form-control form-control-border ${!pl_ob_data_ana_fim && 'is-invalid'
                            }`}
                        name="pl_ob_data_ana_fim"
                        value={pl_ob_data_ana_fim}
                        min={formateValidatedDate(pl_ob_data_ana_ini)}
                        onChange={onChange}
                        placeholder="_/__/____"
                    />
                </div>
            </div>
            <div className="col-12 text-secondary mt-3">Previsão</div>
            <div className="form-group col-6">
                <label>Obra prevista para começar:</label>
                <div className="input-group">
                    <input
                        type="date"
                        className={`form-control form-control-border ${!pl_ob_data_prev_ini && 'is-invalid'
                            }`}
                        name="pl_ob_data_prev_ini"
                        value={pl_ob_data_prev_ini}
                        onChange={onChange}
                        placeholder="_/__/____"
                    />
                </div>
            </div>
            <div className="form-group col-6">
                <label>Obra prevista para terminar:</label>
                <div className="input-group">
                    <input
                        type="date"
                        className={`form-control form-control-border ${!pl_ob_data_prev_fim && 'is-invalid'
                            }`}
                        onBlur={onBlurPrevDate}
                        name="pl_ob_data_prev_fim"
                        min={formateValidatedDate(pl_ob_data_prev_ini)}
                        placeholder="_/__/____"
                    />
                </div>
            </div>
            <div className="col-12 text-secondary mt-3">Programação</div>
            <div className="form-group col-6">
                <label>Obra programada para começar:</label>
                <div className="input-group">
                    <input
                        type="date"
                        className={`form-control form-control-border ${!pl_ob_data_program_ini && 'is-invalid'
                            }`}
                        name="pl_ob_data_program_ini"
                        value={pl_ob_data_program_ini}
                        onChange={onChange}
                        placeholder="_/__/____"
                    />
                </div>
            </div>
            <div className="form-group col-6">
                <label>Obra programada para terminar:</label>
                <div className="input-group">
                    <input
                        type="date"
                        className={`form-control form-control-border ${!pl_ob_data_program_fim && 'is-invalid'
                            }`}
                        name="pl_ob_data_program_fim"
                        value={pl_ob_data_program_fim}
                        min={formateValidatedDate(pl_ob_data_program_ini)}
                        onChange={onChange}
                        placeholder="_/__/____"
                    />
                </div>
            </div>
        </div>
    )
}
export default FormMonitoring