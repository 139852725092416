/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react'
import { formateDate } from '../../../services/dateHandless'
const RoutineConservationReport = ({ data, item }) => {
	return (
		<div className="card mt-3">
			<div className="card-header">
				<h3 className="card-title col-8 d-none d-md-block">
                    Relatório de Fiscalizações de Não Conformidade
				</h3>
			</div>
			{Array.isArray(data[item]) &&
                data[item].map((el, i) => (
                	<div className="card-body" key={i}>
                		<div className="w-100 mb-3">{el.serv_sub_cat_desc}</div>
                		<div className="row">
                			<div className="col-4">
                				<div className="text-secondary">
                                    Código Fiscalização:
                				</div>{' '}
                				{el?.ped_venda_cod}
                			</div>
                			<div className="col-6">
                				<div className="text-secondary">Concessionária:</div>{' '}
                				{el.conc_razaosocial}
                			</div>
                			<div className="col-2">
                				<div className="text-secondary">Lote:</div>{' '}
                				{el.lot_conc_desc}
                			</div>
                			<div className="col-12">
                				<div className="text-secondary">Localização:</div>
                			</div>
                			<div className="col-12">{el.rod_desc}</div>
                			<div className="col-4">
                				<div className="text-secondary">KM+MTS - Inicial:</div>{' '}
                                491 + 100 KM+MTS
                			</div>
                			<div className="col-4">
                				<div className="text-secondary">KM+MTS - Final:</div>{' '}
                                491 + 100
                			</div>
                			<div className="col-4">
                				<div className="text-secondary">Sentido:</div> Leste
                			</div>
                			<div className="col-6">
                				<div className="text-secondary">Atividade:</div>{' '}
                				{el.serv_sub_cat_desc}
                			</div>
                			<div className="col-6">
                				<div className="text-xecondary">Constatação:</div>{' '}
                				{formateDate(el.pvs_prazo_ini)}
                			</div>
                			<div className="col-6">
                				<div className="text-secondary">
                                    Data Limite para Reparo:{' '}
                					{formateDate(el.pvs_prazo_fim)}
                				</div>
                			</div>
                			<div className="col-12 text-secondary mt-3 mb-3">
                                Fotos
                			</div>
                			{Array.isArray(el?.relatorio_fotografico) &&
                                el.relatorio_fotografico.map((el) => (
                                	<div className="col-4" key={el.pvs_loc_foto_cod}>
                                		<img
                                			src={el.pvs_loc_foto_path}
                                			alt={`relatorio_foto-${el.pvs_loc_foto_cod}`}
                                			style={{ width: '100%', maxHeight: 250 }}
                                		/>
                                	</div>
                                ))}
                		</div>
                		<hr className="w-100" />
                	</div>
                ))}
		</div>
	)
}
export default RoutineConservationReport