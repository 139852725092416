/**
 * Checks if the given date is before the current date.
 * @param {Date|string|number} date - The date to compare. It can be a Date object, a string representing a date, or a number representing a timestamp.
 * @returns {boolean} Returns true if the given date is before the current date; otherwise, false.
 */
export const isBeforeDate = (date) => {
	const pickedDatestr = new Date(date).toDateString()
	const pickedDate = new Date(Date.parse(pickedDatestr.replace(/-/g, ' ')))
	const todaysDate = new Date()
	todaysDate.setHours(0, 0, 0, 0)
	return pickedDate <= todaysDate
}
/**
 * Formats the given date or the current date into a string with the format 'yyyy-mm-dd'.
 * If no date is provided, the current date is used.
 * @param {Date|string|number} [date=new Date()] - The date to format. It can be a Date object, a string representing a date, or a number representing a timestamp.
 * @returns {string} The formatted date string in the 'yyyy-mm-dd' format.
 */
export const formateValidatedDate = (date = new Date()) => {
	const now = new Date(date)
	const day = ('0' + (now.getDate())).slice(-2)
	const month = ('0' + (now.getMonth() + 1)).slice(-2)
	const today = now.getFullYear() + '-' + (month) + '-' + (day)
	return today
}
export const generateDateTimeStampJS = () => {
	return new Date().getTime()
}
export const getToday = () => {
	const today = new Date()
	const dayBr = new Date().toLocaleDateString('pt-BR')
	const minutes = today.getMinutes() <= 9 ? `0${today.getMinutes()}` : today.getMinutes()
	const hours = today.getHours() <= 9 ? `0${today.getHours()}` : today.getHours()
	const result = `${dayBr} às ${hours}:${minutes}`
	return result
}
export const addDate = (addDays, date) => {
	const time = new Date() || new Date(date)
	const newDate = new Date()
	newDate.setDate(time.getDate() + Number(addDays))
	return formateValidatedDate(newDate)
}
export const rmDate = (rmDays = 30, date) => {
	const time = new Date() || new Date(date)
	const outraData = new Date()
	outraData.setDate(time.getDate() - Number(rmDays))
	return formateValidatedDate(outraData)
}
/**
 * Checks if a given string represents a date in the format "YYYY-MM-DD" and formats it to "DD/MM/YYYY".
 * @param {string} str - The string representing the date in the format "YYYY-MM-DD".
 * @returns {string} - The formatted date in the format "DD/MM/YYYY", or returnWhenNullData if the input string is not in the expected format.
 */
export const formateDate = (data = new Date()) => {
	const returnWhenNullData = () => {
		let date = new Date(data || new Date())
		let options = {
			year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'America/Sao_Paulo'
		}
		return date.toLocaleDateString('pt-BR', options)
	}
	// Check if the string matches the pattern "YYYY-MM-DD"
	const regex = /^\d{4}-\d{2}-\d{2}$/
	if (!regex.test(data)) {
		// If the string does not match the expected format, return returnWhenNullData
		return returnWhenNullData()
	}
	const str = data
	// Split the year, month, and day from the string
	const [year, month, day] = str.split('-')

	// Format the date to "DD/MM/YYYY"
	const formattedDate = `${day}/${month}/${year}`
	if (formattedDate === 'Invalid Date') {
		return new Date().toLocaleString()
	}
	return formattedDate
}
/**
 * Formats the given date into a string with the format 'dd/mm/yyyy hh:mm:ss'.
 * If no date is provided, the current date and time are used.
 * @param {Date|string|number} data - The date to format. It can be a Date object, a string representing a date, or a number representing a timestamp.
 * @returns {string} The formatted date and time string in the 'dd/mm/yyyy hh:mm:ss' format.
 */
export const formateHours = (data) => {
	/**
	 * The date object representing the provided date or the current date and time.
	 * @type {Date}
	 */
	let date = new Date(data || new Date())

	/**
	 * Options for formatting the date and time string.
	 * @type {Object}
	 */
	let options = {
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		timeZone: 'America/Sao_Paulo'
	}

	/**
	 * The formatted date and time string.
	 * @type {string}
	 */
	const result = date.toLocaleDateString('pt-BR', options)
	return String(result).substring(12, result.length)
}

export const infoCalendar = currentMonth => {
	const currentDate = new Date()
	const currentYear = currentDate.getFullYear()
	const currentHours = currentDate.getHours()
	const currenMinutes = currentDate.getMinutes()
	const currentSeconds = currentDate.getSeconds()
	const firstDayOfMonth = new Date(currentYear, currentMonth, 1)
	const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0)
	const nameMonth = `${firstDayOfMonth.toLocaleString('default', { month: 'long' })} ${currentYear}`
	return {
		currentDate,
		currentYear,
		currentHours,
		currenMinutes,
		currentSeconds,
		firstDayOfMonth,
		lastDayOfMonth,
		nameMonth
	}
}
export const parseFromMysqlDateToJSDate = date => {
	if (!date) {
		return ''
	}
	const string_date = date
	if (!string_date || typeof string_date !== 'string') {
		return new Date().getTime()
	}
	const replace_string_date = string_date
		.replace('T', ' ')
		.replace('.000000Z', '')
	const mysqlDate = replace_string_date
	const dateObj = new Date(mysqlDate)
	const timestamp = dateObj.getTime()
	const locale = window.navigator.userLanguage || window.navigator.language
	return new Date(timestamp).toLocaleDateString(locale)
}
export const loadMinDate = (setMinDate) => {
	const currentYear = new Date().getFullYear()
	const firstDay = new Date(currentYear, 0, 1)
	setMinDate(formateValidatedDate(firstDay))
}
export const LoadMaxDate = (setMaxDate) => {
	const currentYear = new Date().getFullYear()
	const lastDay = new Date(currentYear, 11, 31)
	setMaxDate(formateValidatedDate(lastDay))
}
/**
 * Converts a given date string to a timestamp with Brazil's timezone.
 * 
 * @param {string} dateString - The date string to convert (in ISO 8601 format).
 * @returns {number} The timestamp with Brazil's timezone.
 */
export function convertToBrazilTimestamp(dateString) {
	const date = new Date(dateString)
	const brazilTimezoneOffset = -3 * 60 // Brazil's timezone offset from UTC (in minutes)
	const timestampWithBrazilTimezone = date.getTime() + (brazilTimezoneOffset * 60 * 1000)
	return timestampWithBrazilTimezone
}

/**
 * Calculates the difference in days between two timestamps.
 * 
 * @param {number} timestamp1 - The first timestamp.
 * @param {number} timestamp2 - The second timestamp.
 * @returns {number} The difference in days between the two timestamps.
 */
export function calculateDaysDifference(timestamp1, timestamp2) {
	// Convert timestamps to milliseconds
	const millisecondsPerDay = 24 * 60 * 60 * 1000 // Number of milliseconds in a day
	const date1 = new Date(timestamp1)
	const date2 = new Date(timestamp2)


	// Calculate difference in milliseconds
	const differenceInMilliseconds = Math.abs(date1 - date2)


	// Convert difference to days
	const differenceInDays = Math.floor(differenceInMilliseconds / millisecondsPerDay)


	return differenceInDays
}
/**
 * Converts a string representing a date to a formatted date string.
 * This function takes a string representing a date and converts it to a formatted date string
 * with the year and month preserved, but the day set to the last day of the month.
 * @param {string} dateString - A string representing a date (e.g., "2024-04-15").
 * @returns {string} The formatted date string with the year and month preserved and the day set to the last day of the month (e.g., "2024-04-30").
 */
export function convertStringDate(dateString) {
	// Convert the string to a date object
	const date = new Date(dateString)

	// Extract the year and month from the date object
	const year = date.getFullYear()
	const month = date.getMonth() + 1 // January is 0, so we add 1 to get the correct month

	// Calculate the last day of the month
	const lastDay = new Date(year, month, 0).getDate()

	// Create the final date string with the year, month, and last day
	const finalDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`

	// Return the formatted date string
	return finalDate
}

/**
 * Calculate the difference in months between two formatted dates.
 *
 * This function calculates the difference in months between two dates formatted as "Y-m-d".
 *
 * @param {string} date1 The first formatted date (YYYY-MM-DD).
 * @param {string} date2 The second formatted date (YYYY-MM-DD).
 * @returns {number} The difference in months between the two dates.
 *
 * @example
 * // Calculate the difference in months between '2024-06-01' and '2025-12-15'
 * const differenceInMonths = monthDifference('2024-06-01', '2025-12-15');
 * console.log(differenceInMonths); // Output: 18
 */
export function monthDifference(date1, date2) {
	try {
		// Create Date objects from formatted strings
		const dateObj1 = new Date(date1);
		const dateObj2 = new Date(date2);

		// Check for valid dates
		if (isNaN(dateObj1.getTime()) || isNaN(dateObj2.getTime())) {
			return 0; // Handle invalid dates
		}

		// Normalize month values (0-11)
		const month1 = dateObj1.getMonth();
		const month2 = dateObj2.getMonth();

		// Normalize year values
		const year1 = dateObj1.getFullYear();
		const year2 = dateObj2.getFullYear();

		// Calculate year difference
		let yearDiff = year2 - year1;

		// Calculate month difference considering year changes
		let monthDiff = (month2 + 12 * yearDiff) - month1;

		// Adjust for cases where date2 is before date1 in the same year
		if (dateObj2 < dateObj1) {
			monthDiff -= 12;
		}

		return monthDiff;
	} catch (error) {
		console.log(error)
		return 0
	}
}
/**
 * Converts a date string into MySQL date format (YYYY-MM-DD HH:MM:SS).
 *
 * @param {string} dateString - The date string to be converted. Example: "Thu Apr 13 2023 00:00:28 GMT-0300 (Brasilia Standard Time)"
 * @returns {string} - The date formatted as a MySQL date string. Example: "2023-04-13 00:00:28"
 * 
 * @example
 * const date1 = "Thu Apr 13 2023 00:00:28 GMT-0300 (Brasilia Standard Time)";
 * const mysqlDate1 = convertToMySQLDate(date1);
 * console.log(mysqlDate1); // Output: "2023-04-13 00:00:28"
 * 
 * const date2 = "Sat Dec 30 1899 10:07:08 GMT-0306 (Brasilia Standard Time)";
 * const mysqlDate2 = convertToMySQLDate(date2);
 * console.log(mysqlDate2); // Output: "1899-12-30 10:07:08"
 */
export function convertToMySQLDate(dateString) {
	// Parse the date string into a Date object
	const date = new Date(dateString);

	// Get individual components of the date
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const seconds = String(date.getSeconds()).padStart(2, '0');

	// Construct the MySQL date string
	const mysqlDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

	return mysqlDateString;
}
