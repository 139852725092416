import React, { useContext, useRef } from "react";
import { formateDate, formateValidatedDate } from "../../../../../services/dateHandless";
import { formContext } from "../../../../../context/formContext";
import FormMonitoringProgressConstructionPlan from "../../../../form/monitoringProgressConstructionPlan";
const RenderTH = ({ item, isNew=false, handleDelete=null }) => {
    const close = useRef(null)
    const button = useRef(null)
    const { handleForm, state } = useContext(formContext)
    const {
        acob_date_prev_fim,
        acob_date_prev,
    } = state
    const handleEdit = () => {
        handleForm({
            ...item,
            acob_date_prev_fim: formateValidatedDate(item.acob_date_prev_fim),
            acob_date_prev: formateValidatedDate(item.acob_date_prev),
        })
        if (button?.current?.click) {
            button.current.click()
        }
    }
    return (
        <>
            <tr>
                {!isNew&&<th scope="row">{item.acob_pl_ob_cod}</th >}
                <td>{formateDate(item.acob_date_prev)}</td>
                <td>{formateDate(item.acob_date_prev_fim)}</td>
                <td>{parseFloat(item.acob_perc_prev).toFixed(2)}</td>
                <td>
                    {!isNew&&<button className="btn btn-danger btn-sm mr-2" onClick={handleEdit}>
                        <i className="fa fa-edit"></i>
                    </button>}
                    <button type="button" className="btn btn-secondary btn-sm" onClick={()=>handleDelete(item.id)}>
                        <i className="fa fa-trash"></i>
                    </button>
                </td>
            </tr>
            <button className="d-none" data-toggle="modal" ref={button} data-target="#modalMonitoring"></button>
            <div className="modal fade bd-example-modal-lg" id="modalMonitoring" tabindex="-1" role="dialog" aria-labelledby="modalMonitoring" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title"  id="modalMonitoring" >
                                Alterando Cronograma para o período {formateDate(acob_date_prev)} a  {formateDate(acob_date_prev_fim)}
							</h5>
							<button ref={close} type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setOpenModal(closeModal)}>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<FormMonitoringProgressConstructionPlan handleCloseModal={close}/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default RenderTH