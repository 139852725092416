import React from 'react'
import { formateDate } from '../../../services/dateHandless'
import { getBaseUrlFile } from '../../../webService'
const ListNotified = ({ item }) => {
	const {
		not_pvs_cod,
		not_desc,
		not_pvs_data,
		not_pvs_path_doc,
		conc_nome,
		ped_venda_cod,
	} = item
	const handleView = () => {
		window.open(`${getBaseUrlFile()}${not_pvs_path_doc}`).focus()
	}
	return (
		<div className="row p-3" key={not_pvs_cod}>
			<div className="mb-3 col-sm-12 col-md-12">{conc_nome}</div>
			<div className="mb-3 col-sm-12 col-md-6">Notificada pelo sequinte motivo:</div>
			<div className="mb-3 col-sm-12 col-md-12">{not_desc}</div>
			<div className="mb-3 col-sm-6 col-mb-2">
				<span className="text-secondary mr-2">No dia:</span>
				{formateDate(not_pvs_data)}
			</div>
			<div className="mb-3 col-md-8">
				<span className="text-secondary">Não Conformidade° </span> {ped_venda_cod}
			</div>
			<div className="mb-3 col-md-2">
				<button onClick={handleView} className="btn btn-danger">
          Visualizar
				</button>
			</div>
		</div>
	)
}
export default ListNotified
