import React, { useContext } from "react";
import { formContext } from "../../../context/formContext";
const FormCompany = () => {
    const { state, onChange, CNPJ } = useContext(formContext)
    const {
        emp_ativo,
        emp_cnpj,
        emp_nome
    } = state
    return (
        <div className="row">
            <div className="form-group col-12">
                <label>Razão social:</label>
                <div className="input-group">
                    <input type="text"
                        onChange={onChange}
                        name='emp_nome' value={emp_nome}
                        className={`form-control form-control-border ${!emp_nome && 'is-invalid'}`}
                        placeholder='Razão social da empresa' />
                </div>
            </div>
            <div className="form-group col-md-6 col-sm-6">
                <label>CNPJ:</label>
                <div className="input-group">
                    <input type="text"
                        onChange={onChange}
                        name='emp_cnpj' value={CNPJ(emp_cnpj)}
                        className={`form-control form-control-border ${!emp_cnpj && 'is-invalid'}`}
                        placeholder='00.000.000/0000-00' />
                </div>
            </div>
            <div className="form-group col-md-6 col-sm-6">
                <label>Situação:</label>
                <div className="input-group">
                    <select name="emp_ativo" value={emp_ativo}
                        className={`form-control form-control-border ${!emp_cnpj && 'is-invalid'}`}
                        onChange={onChange}
                    >
                        <option value={0}>
                            Inativo
                        </option>
                        <option value={1}>
                            Ativo
                        </option>
                    </select>
                </div>
            </div>
        </div>
    )
}
export default FormCompany