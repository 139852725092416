import React, { useContext, useEffect, useRef, useState } from 'react'
import ContentHeader from '../../../components/contentHeader'
import ListServiceOversight from '../../../components/list/serviceOversight'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import ImageItem from '../../../components/list/img'
import MyMap from '../../../components/map'
import { useNavigate } from 'react-router-dom'
import { authContext } from '../../../context/authContext'
import ListCheckinspector from '../../../components/list/checkinspector'
import Camera from '../../../components/camera'
import { getPropsDimissions } from '../../../services/getPropsElNode'
import { handleWaring } from '../../../services/isValid'
import { getExt } from '../../../services/files'
import { styleImage } from '../../../css/globlalStyle'
import FormDetailsOversight from '../../../components/form/detailsOversight'
const OversightView = () => {
	const [markersPhotos, setMarkersPhoto] = useState(null)
	const [showPhotos, setShowPhotos] = useState(true)
	const [showMap, setShowMap] = useState(true)
	const [showCheckInspector, setShowCheckInspector] = useState(true)
	const [markersCheckInspector, setMarkersInspector] = useState(null)
	const [dimissions, setDimissions] = useState(null)
	const [photos, setPhotos] = useState(null)
	const [disableShowCheckInspection, setDisableShowInspection] = useState(false)
	const [errorMap, setErrorMap] = useState(false)
	const card_map_ref = useRef()
	const card_photo_ref = useRef()
	const card_fisc_ref = useRef()
	const { state, handleForm, showCamera, setShowCamera } =
		useContext(formContext)
	const {
		getItemOversightService,
		service,
		getItemOversightPhotos,
		getItemStatusOversight,
		getAllItemCheckInspection,
		check_inspection,
		getItemApiKey,
		setInfoRequest
	} = useContext(requestContext)
	const { user } = useContext(authContext)
	const navigate = useNavigate()
	const {
		ped_venda_cod,
		pv_loc_lat,
		pv_loc_lng,
		sisf_sinc_id,
	} = state
	useEffect(() => {
		if (user?.set_nivel <= 1) {
			getItemStatusOversight()
		}
	}, [user])
	useEffect(() => {
		setShowCamera(false)
		getItemApiKey()
	}, [])
	useEffect(() => {
		if (card_photo_ref) {
			if (showPhotos) {
				card_photo_ref.current?.scrollIntoView()
			}
		}
	}, [showPhotos])
	useEffect(() => {
		if (!showCheckInspector || showPhotos) {
			window.scrollTo(0, 0)
		}
	}, [showCheckInspector])
	useEffect(() => {
		loadItems()
	}, [ped_venda_cod])
	useEffect(() => {
		const loadServicesPhotos = () => {
			try {
				if (Array.isArray(service?.data)) {
					const pvs_codObject = service.data.map((item) => {
						return {
							pvs_cod: item.pvs_cod,
						}
					})
					pvs_codObject.forEach(async (element) => {
						const { pvs_cod } = element
						if (pvs_cod) {
							const photosItems = []
							const response = await getItemOversightPhotos(pvs_cod)
							if (response) {
								const { data } = response
								if (data.length > 0) {
									data.forEach((el) => photosItems.push(el))
								}
							}
							setPhotos(photosItems)
						}
					})
				}
			} catch (error) {
				setInfoRequest(handleWaring(String(error)))
			}
		}
		loadServicesPhotos()
	}, [service])
	useEffect(() => {
		const loadPhotosMarkers = () => {
			if (!Array.isArray(photos) || !Array.isArray(service?.data)) {
				return
			}
			const makerItems = loadMarkerItems(photos)
			if (makerItems) {
				setMarkersPhoto(makerItems)
			}
		}
		loadPhotosMarkers()
	}, [photos, service])
	const loadMarkerItems = (items) => {
		try {
			const marker = items.filter(item => {
				if (item.pvs_loc_foto_lat || item.pvs_loc_foto_lng) {
					return item
				}
				return false
			})
			if (marker?.length === 0) {
				setErrorMap(true)
				return []
			}
			const { data: service_data } = service
			const newMarkers = marker.map(item => {
				const findservice = service_data.find(pv_servico => parseInt(pv_servico.pvs_cod) === parseInt(item.pvs_loc_foto_pedidovenda))
				return {
					lat: item.pvs_loc_foto_lat,
					lng: item.pvs_loc_foto_lng,
					icon_color: findservice?.st_cor_valor,
					content: `${findservice.pvs_obs} - ${findservice?.st_desc}`,
				}
			})
			setErrorMap(false)
			return newMarkers
		} catch (error) {
			setErrorMap(true)
			setInfoRequest(handleWaring(`
      'Não possível mostrar no mapa a localização das atividades. ${String(error)}`))
		}
	}
	useEffect(() => {
		const loadCenterMap = () => {
			try {
				const findFistLatLng = service.data.find(item => item.pvs_lat && item.pvs_lng)

				if (findFistLatLng) {
					handleForm({
						pv_loc_lat: findFistLatLng.pvs_lat,
						pv_loc_lng: findFistLatLng.pvs_lng
					})
				} else {
					setErrorMap(true)
				}
			} catch (error) {
				setInfoRequest(handleWaring(`
        'Não possível mostrar no mapa a localização das atividades. ${String(error)}`))
			}
		}
		if (Array.isArray(service?.data)) {
			loadCenterMap()
		}
	}, [service])
	useEffect(() => {
		const loadCheckInspection = () => {
			try {
				const newData = []
				if (check_inspection?.total > 0) {
					const { data } = check_inspection
					for (const item of data) {
						if (item.fisc_lat && item.fisc_lng) {
							newData.push({
								lat: item.fisc_lat,
								lng: item.fisc_lng,
								content: item.fisc_obs,
								icon_color: '#007bff',

							})
						}
					}
					if (!newData.length) {
						return setDisableShowInspection(true)
					}
					setDisableShowInspection(false)
					setShowCheckInspector(true)
					setMarkersInspector(newData)
				}
			} catch (error) {
				setInfoRequest(handleWaring(String(error)))
			}
		}
		loadCheckInspection()
	}, [check_inspection])
	useEffect(() => {
		if (!pv_loc_lat && !pv_loc_lng) {
			setShowPhotos(true)
		}
	}, [pv_loc_lat, pv_loc_lng])
	const loadItems = () => {
		setDimissions(getPropsDimissions(['detalis', 'location']))
		getItemOversightService(ped_venda_cod)
		getAllItemCheckInspection(ped_venda_cod)
	}
	const handleCancel = () => {
		handleForm({
			conc_nome: '',
			lot_conc_desc: '',
			ped_venda_cod: '',
			ped_venda_date: '',
			ped_venda_status: '',
			ped_venda_concessionaria: '',
			ped_venda_responsavel: '',
			ped_venda_rodovia: '',
			st_desc: '',
			st_cor_valor: '',
			rod_desc: '',
			pv_loc_cod: '',
			pv_loc_pedidovenda: '',
			pv_loc_sentido: '',
			pv_loc_km_ini: '',
			pv_loc_km_init_mts: '',
			pv_loc_km_fim: '',
			pv_loc_km_fim_mts: '',
			pv_loc_obs: '',
			pv_loc_lat: '',
			pv_loc_lng: '',
			rod_sent_desc: '',
			ped_venda_sifs: '',
		})
		navigate('/oversight')
	}
	return (
		<>
			<ContentHeader title={`Não Conformidade N° ${sisf_sinc_id || ped_venda_cod}`} />
			<div className="content p-3">
				<div className="card" id="detalis">
					<div className="card-header bg-danger">
						<h3 className="card-title">Detalhes</h3>
						<div className="card-tools">
							<button
								type="button"
								className="btn btn-tool"
								data-card-widget="collapse"
							>
								<i className="fas fa-minus"></i>
							</button>
							<button
								type="button"
								className="btn btn-tool"
								data-toggle="modal"
								data-target="#concessionaria"
							>
								<i className="fa fa-question-circle"></i>
							</button>
						</div>
					</div>
					<FormDetailsOversight
						showPhotos={showPhotos}
						setShowPhotos={setShowPhotos}
						showMap={showMap}
						setShowMap={setShowMap}
						disableShowCheckInspection={disableShowCheckInspection}
						showCheckInspector={showCheckInspector}
						setShowCheckInspector={setShowCheckInspector}
					/>
				</div>
				<div className="card">
					<div className="card-header bg-danger">
						<h3 className="card-title">Serviços solicitados</h3>
						<div className="card-tools">
							<button
								type="button"
								className="btn btn-tool"
								data-card-widget="collapse"
							>
								<i className="fas fa-minus"></i>
							</button>
							<button
								type="button"
								className="btn btn-tool"
								data-toggle="modal"
								data-target="#concessionaria"
								onClick={() => loadItems()}
							>
								<i className="fa fa-retweet"></i>
							</button>
						</div>
					</div>
					<div className="card-body">
						<ListServiceOversight data={service} />
					</div>
				</div>
				<div className="card" ref={card_photo_ref}>
					{showPhotos && (
						<>
							<div className="card-header bg-danger">
								<h3 className="card-title">
									Fotos tiradas na abertura da Não Conformidade
								</h3>
								<div className="card-tools">
									<button
										type="button"
										className="btn btn-tool"
										data-card-widget="collapse"
									>
										<i className="fas fa-minus"></i>
									</button>
									<button
										type="button"
										className="btn btn-tool"
										data-toggle="modal"
										data-target="#concessionaria"
									>
										<i className="fa fa-question-circle"></i>
									</button>
								</div>
							</div>
							<div
								className="card-body"
								style={{
									height: dimissions?.height || 320,
									overflowY: 'scroll',
								}}
							>
								<div className="row">
									{Array.isArray(photos)
										? photos.map((item, i) => (
											getExt(item.pvs_loc_foto_path) === 'mp4' ? (
												<div className='col-md-6 col-sm-12' key={i}>
													<video controls width={'100%'} style={styleImage} height={750} src={item.pvs_loc_foto_path} autoPlay></video>
												</div>
											) : (
												<div className="col-md-4 col-sm-12" key={i}>
													<ImageItem
														item={item}
														legend={item.pvs_loc_foto_legenda}
														src={
															item.pvs_loc_foto_path
																? item.pvs_loc_foto_path
																: item.base64
														}
														setPhotos={setPhotos}
														data={photos}
													/>
												</div>
											)
										))
										: 'Sem imagens'}
								</div>
							</div>
						</>
					)}
				</div>
				<div className="card" ref={card_map_ref}>
					{errorMap ? (
						<div className="card-body">
							<div className="text-secondary mb-3">
								Não foi possível mostrar no mapa a localização das atividades
							</div>
							<button className="btn btn-danger" onClick={() => loadMarkerItems(photos)}>
								Tentar novamente <i className="fa fa-location-arrow" aria-hidden="true"></i>
							</button>
						</div>
					) : (
						<>
							<div className="card-header bg-danger">
								<h3 className="card-title">
									Localização das atividades e dos retornos quando houver
								</h3>
								<div className="card-tools">
									<button
										type="button"
										className="btn btn-tool"
										data-card-widget="collapse"
									>
										<i className="fas fa-minus"></i>
									</button>
									<button
										type="button"
										className="btn btn-tool"
										data-toggle="modal"
										data-target="#concessionaria"
									>
										<i className="fa fa-question-circle"></i>
									</button>
								</div>
							</div>
							<div className="card-body p-0 m-0">
								{showMap && (
									showCheckInspector ? (
										<MyMap
											position={{
												lat: Number(pv_loc_lat),
												lng: Number(pv_loc_lng),
											}}
											markersCheckInspector={markersCheckInspector}
										/>
									) : (
										<MyMap
											position={{
												lat: Number(pv_loc_lat),
												lng: Number(pv_loc_lng),
											}}
											markersPhotos={markersPhotos}
										/>
									)
								)}
							</div>
						</>
					)}
				</div>
				<div className="card" ref={card_fisc_ref}>
					<div className="card-header bg-danger">
						<h3 className="card-title">Fiscalização</h3>
						<div className="card-tools">
							<button
								type="button"
								className="btn btn-tool"
								data-card-widget="collapse"
							>
								<i className="fas fa-minus"></i>
							</button>
							<button
								type="button"
								className="btn btn-tool"
								data-toggle="modal"
								data-target="#concessionaria"
								onClick={() => loadItems()}
							>
								<i className="fa fa-retweet"></i>
							</button>
						</div>
					</div>
					<div className="card-body">
						{showCheckInspector && (
							<ListCheckinspector data={check_inspection} />
						)}
					</div>
					{showCamera && <Camera />}
				</div>
			</div>
			<div className="card">
				<div className="card-body">
					<div className="btn btn-secondary" onClick={handleCancel}>
						<i className="fa fa-arrow-left"></i> Voltar
					</div>
				</div>
			</div>
		</>
	)
}
export default OversightView
