import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { formateDate } from '../../../services/dateHandless'
import { ProgressBar } from '../../progressBar'
import { authContext } from '../../../context/authContext'
import { formatCurrency } from '../../../services/stringHandless'
import { handleError } from '../../../services/isValid'
import { namesScreens } from '../../../constants'

const ListContructionPlan = ({ data, itemsSelected, itemsSet, codSelected, codSelectedSet, groupObj, groupObjSet }) => {
	const [items, setItems] = useState(null)
	const navigate = useNavigate()
	const { handleForm, state: { conc_cod,
		rod_cod,
		ped_venda_date_init,
		ped_venda_date_fim,
		type_date } } = useContext(formContext)
	const { postStartConstructionPlan, deletetItemConstructionPlan, setInfoRequest } = useContext(requestContext)
	const { user } = useContext(authContext)
	useEffect(() => {
		if (Array.isArray(data)) {
			setItems(data)
		}
	}, [data])
	const handleEdit = (item) => {
		const { pl_ob_tipo: tip_ob_cod } = item
		handleForm({
			...item,
			tip_ob_cod
		})
		navigate(namesScreens.CONSTRUCTION_PLANVIEW)
	}
	const handleDelete = (item) => {
		const { conc_nome, pl_ob_cod } = item
		const confirm = window.confirm(`Você deseja mesmo excluir o plano de obras para a concessionária ${conc_nome} ?`)
		if (!confirm) {
			return
		}
		deletetItemConstructionPlan({
			id: pl_ob_cod,
			id_from_filter: conc_cod,
			rod_cod,
			ped_venda_date_init,
			ped_venda_date_fim,
			type_date
		})
	}
	const handleStart = (item) => {
		if (user?.set_nivel !== 0) {
			return
		}
		const { ped_venda_status } = item
		let add_message = ped_venda_status === 83 ? 'O sistema entenderá que você está aprovando a execução de todos os serviços inseridos na obra' : ''
		const confirm = window.confirm(`Você deseja mesmo inicar o plano de obra ${item.pl_ob_cod} para ${item.conc_nome} ? ${add_message}`)
		if (!confirm) {
			return
		}
		postStartConstructionPlan({
			id: item.pl_ob_cod,
		})
	}
	if (!Array.isArray(items)) {
		return <div></div>
	}
	const defineSelected = (cod, pl_ob_cod, conc_nome, rod_cod) => {

		if (groupObj?.conc_nome === null || itemsSelected?.length === 0) {
			groupObjSet({ conc_nome: conc_nome, rod_cod: rod_cod })
		} else {
			if (groupObj?.conc_nome !== conc_nome || groupObj?.rod_cod !== rod_cod) {
				return setInfoRequest(handleError('Por favor selecione planos de obra da mesma concessionária e rodovia'))
			}
		}

		if (itemsSelected.includes(cod)) {
			const tempArr = [...itemsSelected]
			const index = tempArr.indexOf(cod)
			tempArr.splice(index, 1)
			itemsSet(tempArr)

			const tempArr2 = [...codSelected]
			const index2 = tempArr2.indexOf(pl_ob_cod)
			tempArr2.splice(index2, 1)
			codSelectedSet(tempArr2)

		} else {
			const tempArr = [...itemsSelected]
			tempArr.push(cod)
			const tempArr2 = [...codSelected]
			tempArr2.push(pl_ob_cod)

			if (tempArr?.length === 0) {
				groupObjSet({ conc_nome: null, rod_cod: 0 })
			}
			itemsSet(tempArr)
			codSelectedSet(tempArr2)
		}
	}
	const checkItsMarked = (cod) => {
		return itemsSelected.includes(cod)
	}
	return (
		items.map(item => (
			<div className="row mb-3 p-3" key={item?.pl_ob_cod}>
				<div className="mb-3 col-md-6 col-sm-6">
					<span className="text-secondary">Concessionária: </span>
					{item.conc_nome}
				</div>
				<div className="mb-3 col-md-3 col-sm-6">
					<span className="text-secondary">N° do plano de obra: </span>
					{item?.sisf_sinc_int_amp_id || item.pl_ob_cod}
				</div>
				<div className="mb-3 col-md-3 col-sm-6">
					<button
						className="btn btn-danger btn-sm"
						onClick={() => defineSelected(item.ped_venda_cod, item.pl_ob_cod, item.conc_nome, item.rod_cod)}
					>
						{checkItsMarked(item.ped_venda_cod) ? (
							<>
								<i className="fa fa-check-square"></i> Selecionado
							</>
						) : 'Selecionar'}
					</button>
				</div>
				<div className="mb-3 col-md-6 col-sm-12">
					<span className="text-secondary">Nome da obra ou do objeto: </span>
					{item.pl_ob_desc_object}
				</div>
				<div className='mb-3 col-md-3 col-sm-6'>
					<span className='text-secondary'>Item do serviço: </span>
					{item.pl_ob_cod_item}
				</div>
				<div className="mb-3 col-md-3 col-sm-4 text-center mb-2">
					<span
						className="badge text-center text-light mr-2 p-2"
						style={{ backgroundColor: item.st_cor_valor }}
					>
						{item.st_desc}
					</span>
				</div>
				<div className="mb-3 col-md-3 col-sm-6">
					<span className="text-secondary">Tipo de obra: </span>
					{item.tip_ob_desc}
				</div>
				{user?.set_nivel == 0 ? (
					<div className="mb-3 col-md-3 col-sm-6">
						<span className="text-secondary">Valor da obra: </span>
						{formatCurrency(item.pl_ob_valor)}
					</div>
				) : ''}
				<div className="col-md-3 col-sm-12 mb-3">
					{item.tip_ped_venda_desc && (
						<div className="col-md-1 col-sm-6 mr-2">
							<span
								className="badge text-light bg-secondary text-center p-2"
							>
								{item.tip_ped_venda_desc}
							</span>
						</div>
					)}
				</div>
				<div className="col-md-1 col-sm-12 mb-3">
					{item.sinc_st_desc ? (
						<span className='text-secondary'>No SISF:</span>
					) : ''}
				</div>
				<div className="col-md-2 col-sm-12 mb-3">
					{item.sinc_st_desc && (
						<>
							<div className="col-md-1 col-sm-6 mr-2">
								<span
									className="badge text-light bg-secondary text-center p-2"
								>
									{item.sinc_st_desc}
								</span>
							</div>
						</>
					)}
				</div>
				{item.pl_ob_data_exec_ini && (
					<div className="mb-3 col-md-3 col-sm-6">
						<span className="text-secondary">Iniciada no dia</span>{' '}
						{formateDate(`${item?.pl_ob_data_exec_ini} 11:59:00`)}
					</div>
				)}{' '}
				{item?.pl_ob_data_exec_fim && (
					<div className="mb-3 col-md-3 col-sm-6">
						<span className="text-secondary">Finalizada no dia</span>{' '}
						{formateDate(`${item?.pl_ob_data_exec_fim} 11:59:00`)}
					</div>
				)}
				<div className="mb-3 col-md-3 col-sm-6">
					<span className="text-secondary">Inicio Previsto:</span>{' '}
					{formateDate(`${item.pl_ob_data_prev_ini} 11:59:00`)}
				</div>
				<div className="mb-3 col-md-3 col-sm-6">
					<span className="text-secondary">Fim Previsto:</span>{' '}
					{formateDate(`${item.pl_ob_data_prev_fim} 11:59:00`)}
				</div>
				<div className="mb-3 col-md-3 col-sm-8">
					<span className="text-secondary mr-2">Criado por:</span>
					{item.name}
				</div>
				<div className="mb-3 col-md-3 col-sm-8">
					<span className="text-secondary mr-2">Rodovia:</span>
					{item.rod_desc} - {item.rod_km}
				</div>
				<div className="mb-3 col-md-2 col-sm-4 text-center">
					<span className="text-secondary mr-2">Acumulado:</span>
					{parseFloat(Math.abs(item.prog_ob_por_cen_acul)).toFixed(2)}%
				</div>
				<div className="mb-3 col-md-2 col-sm-4 text-center">
					<span className="text-secondary mr-2">Progresso:</span>
					{item.prog_ob_por_cen_progress === null ? '0' : item.prog_ob_por_cen_progress > 99.9 ? '100' : Number(item.prog_ob_por_cen_progress).toFixed(2)} %{' '}
				</div>
				<div className="mb-3 col-md-5 col-sm-6 text-center">
					{item.prog_ob_por_cen_progress >= 100 ? (
						<span className="badge badge-success mr-2">Concluída</span>
					) : (
						user?.set_nivel !== 3 ? (
							<button
								onClick={() => handleStart(item)}
								className="btn btn-secondary btn-sm mr-2"
							>
								{!item.pl_ob_data_exec_ini ? 'Iniciar' : 'Termi.'}{' '}
								<i
									className={
										!item.pl_ob_data_exec_ini ? 'fa fa-play' : 'fa fa-stop'
									}
								></i>
							</button>
						) : (<div></div>)
					)}
					{
						user?.set_nivel !== 3 || item.st_desc === 'Aprovado' ? (
							<>
								<button
									onClick={() => handleEdit(item)}
									className="btn btn-danger btn-sm mr-2"
								>
									<i className="fa fa-edit"></i>
								</button>
								{user.set_nivel === 0 && (
									<button
										onClick={() => handleDelete(item)}
										className="btn btn-secondary btn-sm"
									>
										<i className="fa fa-trash"></i>
									</button>
								)}
							</>
						) : ''
					}

				</div>
				<ProgressBar label={item.prog_ob_por_cen_progress > 99.9 ? '100' : Number(item.prog_ob_por_cen_progress).toFixed(2)} value={item.prog_ob_por_cen_progress > 99.9 ? '100' : Number(item.prog_ob_por_cen_progress).toFixed(2)} />
			</div >
		))
	)
}
export default ListContructionPlan
