import React from 'react'
import HeaderTable from '../../../table/pavement/seguimentos/header'
import { formateDate } from '../../../../services/dateHandless'

const ModalSegments = ({ state }) => {
	const {
		localizacao_levantamento_indice_pavimento_consolidado: { rod_km,
			rod_sent_desc,
			loc_lipc_obs,
			loc_lipc_km_init,
			loc_lipc_km_init_mts,
			loc_lipc_km_fim,
			loc_lipc_km_fim_mts,
		},
		indice_pavimento_consolidado,
		ipc_data,
	} = state
	return (
		<div className='table-responsive'>
			<table className="table table-dark table-hover p-0 m-0">
				<HeaderTable />
				<tbody>
					<tr>
						<td>{rod_km}</td>
						<td>{rod_sent_desc}</td>
						<td>{loc_lipc_obs}</td>
						<td>{`${loc_lipc_km_init},${loc_lipc_km_init_mts} mts`}</td>
						<td>{`${loc_lipc_km_fim},${loc_lipc_km_fim_mts} mts`}</td>
						{Array.isArray(indice_pavimento_consolidado) ? indice_pavimento_consolidado.map(row => {
							const {
								ipc_val,
								ipc_perc,
								ipc_tip_cod,
							} = row
							return (
								<td className='text-center' key={ipc_tip_cod}>{parseFloat(ipc_val).toFixed(2) || parseFloat(ipc_perc).toFixed(2)}</td>
							)
						}) :
							<>
								<td> {0}</td>
								<td> {0}</td>
								<td> {0}</td>
							</>}
						<td> {formateDate(ipc_data)}</td>
					</tr>
				</tbody>
			</table>
			<div className='col-12 mt-3'>
				<p className='card-text'> Segmento não será inserido nesse registro porque não encontramos a rodovia solicitada</p>
			</div>
		</div>
	)
}
export default ModalSegments