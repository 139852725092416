import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { authContext } from '../../../context/authContext'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import returnPosition from '../../../services/geolocation'
import { handleWaring } from '../../../services/isValid'
import { formateDate } from '../../../services/dateHandless'
import ListCheckinspector from '../checkinspector'
const ListOversight = ({ data, printer }) => {
	const { handleForm, state: { type_date, serv_cat_prazo_from, serv_cat_prazo_to } } = useContext(formContext)
	const { deleteItemOversight, setInfoRequest, isConection } =
		useContext(requestContext)
	const { user } = useContext(authContext)
	const navigate = useNavigate()
	const handleClick = (item) => {
		handleForm(item)
		navigate('/oversight/view')
	}
	const handleDelete = async (item) => {
		const confirm = window.confirm(
			`Deseja mesmo cancelar a ocorrência para a concessionária ${item.conc_nome}?`
		)
		if (!confirm) {
			return
		}
		if (confirm) {
			const fisc_obs = window.prompt(
				`Explique o porque você está cancelando essa ocorrência para concessionária ${item.conc_nome}`
			)
			if (!fisc_obs) {
				return
			}
			if (fisc_obs.length > 500) {
				return setInfoRequest(
					handleWaring('Você não pode colocar mais do que 500 caracteres')
				)
			}
			if (fisc_obs.length < 5) {
				return setInfoRequest(
					handleWaring('Você precisa colocar mais do que 5 caracteres')
				)
			}
			const postion = isConection
				? isConection
					? await returnPosition()
					: { lat: 0, lng: 0 }
				: { lat: 0, lng: 0 }
			deleteItemOversight({
				id: item.ped_venda_cod,
				fisc_obs,
				pv_servico: item.pv_servico,
				fisc_lat: postion.lat,
				fisc_lng: postion.lng,
				ped_venda_date_from: serv_cat_prazo_from,
				ped_venda_date_to: serv_cat_prazo_to,
				type_date: type_date
			})
		}
	}
	return data && data.total > 0 ? (
		data.data
			.map((item, i) => (
				<div key={i} className="p3">
					<div className="row">
						<div className="col-md-10 col-sm-12">
							<div className="row">
								<div className="col-md-4 col-sm-12 mb-3">
									{item.conc_nome}
								</div>
								<div className="col-md-4 col-sm-6 mb-3">
									<span className="text-secondary">Não Conformidade n°: </span> {item?.sisf_sinc_id || item.ped_venda_cod}
								</div>
								<div className="col-md-4 col-sm-6 mb-3">
									{item.ped_venda_date ? (
										`Aberto dia ${item.ped_venda_date &&
										formateDate(`${item.ped_venda_date} 00:00:00`)
										}`
									) : (
										<>
											<i className="fa fa-wifi" aria-hidden="true"></i>...
										</>
									)}
								</div>
								{type_date === '1' && (
									<div className="col-md-4 col-sm-6 mb-3 mb-3">
										{item.pvs_prazo_fim ? (
											`Prazo de finalização: ${item.pvs_prazo_fim &&
											formateDate(`${item.pvs_prazo_fim}`)
											}`
										) : (
											<>
												<i className="fa fa-wifi" aria-hidden="true"></i>...
											</>
										)}
									</div>
								)}
								<div className="col-md-4 col-sm-6 mb-3">
									{item.lot_conc_desc && (
										<span className="text-secondary mr-2">
											Lote:
										</span>
									)}
									{item.cont_ncontrato && (
										<div className="text-secondary mr-2">
											N° do Contrato
										</div>
									)}
									<span className="fw-bold mr-1">
										{item.lot_conc_desc || item.cont_ncontrato}
									</span>
								</div>
								<div className="col-md-4 col-sm-12 mb-3">
									<span className="text-secondary">Responsável:</span> {item.name}
								</div>
								<div className="col-md-3 col-sm-12 mb-3">
									{item.st_desc && (
										<div className="col-md-1 col-sm-6 mr-2">
											<span
												className="badge text-light text-center p-2"
												style={{ backgroundColor: item.st_cor_valor }}
											>
												{item.st_desc}
											</span>
										</div>
									)}
								</div>
								{item.gp_desc && (
									<div className="col-md-3 col-sm-12 mb-3">
										<span className='text-secondary'>Grupo: </span>
										{item.gp_desc}
									</div>
								)}
							</div>
						</div>
						<div className="col-md-2 col-sm-12 mb-3 mt-3">
							<div className="row">
								<div className="col-6">
									{item.ped_venda_status !== 4 && !printer && (
										<button
											className="btn btn-danger btn-sm"
											onClick={() => handleClick(item)}
										>
											<i
												className={
													item.ped_venda_status === 3 ? 'fa fa-eye' : 'fa fa-edit'
												}
											></i>
										</button>
									)}
								</div>
								<div className="col-6">
									{user
										? user.set_nivel === 0 &&
										(item.ped_venda_status !== 3 ||
											item.ped_venda_status !== 4) && (
											<button
												className="btn btn-secondary btn-sm"
												onClick={() => handleDelete(item)}
											>
												<i className="fa fa-trash"></i>
											</button>
										)
										: null}
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						{item.pvs_obs && (
							<div className="col-md-6 col-sm-12">
								<span className="text-secondary mr-2">
									Atividade:
								</span>
								{item.pvs_obs}
							</div>
						)}
						<div className="col-md-3 col-sm-12 mb-3">
							{item.tip_ped_venda_desc && (
								<div className="col-md-1 col-sm-6 mr-2">
									<span
										className="badge text-light bg-secondary text-center p-2"
									>
										{item.tip_ped_venda_desc}
									</span>
								</div>
							)}
						</div>
						<div className="col-md-3 col-sm-12 mb-3">
							{item.sinc_st_desc && (
								<div className="col-md-1 col-sm-6 mr-2">
									<span className="badge text-light bg-dark text-center p-2"											>
										{item.sinc_st_desc} no SISF
									</span>
								</div>
							)}
						</div>
						{item.rod_desc && (
							<div className="col-md-6 col-sm-12 mb-3">
								<span className="text-secondary">Rodovia:</span> {item?.rod_km} - {item.rod_desc}
							</div>
						)}
						{item.pv_loc_km_ini && item.pv_loc_km_init_mts && item.pv_loc_km_fim && item.pv_loc_km_fim_mts && (
							<>
								<div className="mb-3 col-md-3 col-sm-6">
									<span className="text-secondary">
										KM Inicial:
									</span> {item.pv_loc_km_ini},{item.pv_loc_km_init_mts} m
								</div>
								<div className="mb-3 col-md-3 col-sm-6">
									<span className="text-secondary">
										KM final:
									</span> {item.pv_loc_km_fim},{item.pv_loc_km_fim_mts} m
								</div>
							</>
						)}
						{Array.isArray(item?.fiscalizacao) ? item?.fiscalizacao?.length && (
							<>
								<div className='col-12'>
									<span className='text-secondary'>
										Fiscalizões
									</span>
								</div>
								<div className='col-12'>
									<ListCheckinspector data={{ data: item?.fiscalizacao, total: item?.fiscalizacao?.length }} />
								</div>
							</>
						) : ''}
					</div>
					<hr />
				</div>
			))
	) : (
		<div className="col-12 p-3 mb-3">{data?.message}</div>
	)
}
export default ListOversight
