import React, { useContext, useEffect, useState } from 'react'
import RenderIcon from '../../icons'
import { formateDate } from '../../../services/dateHandless'
import { Link, useLocation } from 'react-router-dom'
import { requestContext } from '../../../context/requestContext'
import { filterAndRemove } from '../../../services/arrayHandless'
import { namesScreens } from '../../../constants'
const ListMidias = ({ items, setFiles = false, fetchPhotosMovies = false }) => {
	const location = useLocation()
	const [disabled, setDisabled] = useState(false)
	useEffect(() => {
		const controllDisabledInputs = () => {
			const { pathname } = location
			if (pathname === namesScreens.REPORT_CONSTRUCTION_PLAN) {
				return setDisabled(true)
			}
			return setDisabled(false)
		}
		controllDisabledInputs()
	}, [location])
	const { deleteItemOversightPhotos } = useContext(requestContext)
	if (!items?.length) {
		return <div className="row">
			<div className="col-12">
				Não hś itens para serem exibidos
			</div>
		</div>
	}
	const handleOpen = (pvs_loc_foto_path) => {
		if (pvs_loc_foto_path) {
			window.open(pvs_loc_foto_path, 'blank').focus()
		}
	}
	const handleDelete = async (item) => {
		const { pvs_loc_foto_cod, pvs_loc_foto_legenda, pvs_loc_foto_pedidovenda } = item
		const confirm = window.confirm(`Deseja mesmo remover a foto ${pvs_loc_foto_legenda} ?`)
		if ((confirm && fetchPhotosMovies) && !setFiles) {
			const response = await deleteItemOversightPhotos({
				id: pvs_loc_foto_cod,
				pvs_cod: pvs_loc_foto_pedidovenda,
			})
			if (response) {
				fetchPhotosMovies()
			}
		}
		if (confirm && setFiles) {
			const { data } = filterAndRemove(items, 'pvs_loc_foto_cod', pvs_loc_foto_cod)
			setFiles(data)
		}
	}
	return (
		<div className="row mt-3 mb-3">
			{items.sort((a, b) => a.timestamp - b.timestamp).map(item => (
				<div className="col-md-6 col-sm-6 mb-3" key={items.pvs_loc_foto_cod}>
					<div className="card" style={{
						width: '18rem',
					}}>
						<RenderIcon path={item.pvs_loc_foto_path} />
						<div className="card-body">
							<h5 className="card-title w-100">
								{item.pvs_loc_foto_legenda}
							</h5>
							{item?.created_at ? (

								<p>
									<span className="text-secondary mr-2">
										Enviado dia:
									</span>
									{formateDate(item.created_at)}
								</p>
							) : ''}
							{!disabled ? (
								<div className="row">
									<div className="col-6">
										<Link to="#" className="btn btn-danger" onClick={() => handleOpen(item.pvs_loc_foto_path)}>Baixar <i className="fa fa-download" aria-hidden="true"></i></Link>
									</div>
									<div className="col-6" onClick={() => handleDelete(item)}>
										<button className="btn btn-secondary btn-sm">
											<i className="fa fa-trash"></i>
										</button>
									</div>
								</div>
							) : ''}
						</div>
					</div>
				</div>
			))}
		</div>
	)
}
export default ListMidias