/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { requestContext } from "../../../context/requestContext";
import { filterAndRemove, find } from "../../../services/arrayHandless";
import { formateDate } from "../../../services/dateHandless";
import Spinner from "../../spinner";
const ListOversightReport = ({
  items,
  routine,
  itemsSelected,
  selectItem,
  setItemsSelected,
  selectAll,
  handleReport,
}) => {
  const [generatingDirectly, setGeneratingDirectly] = useState(false);
  const { data, loading } =
    useContext(requestContext);

  useEffect(() => {
    if (generatingDirectly) {
      handleReport();
      generateDirectly(false);
    }
  }, [generatingDirectly]);

  const generateDirectly = (ped_venda_cod) => {
    handleSelectItem(ped_venda_cod);
    setGeneratingDirectly(true);
  };

  const handleSelectItem = (ped_venda_cod) => {
    if (Array.isArray(itemsSelected)) {
      const findItem = find(itemsSelected, "ped_venda_cod", ped_venda_cod);
      if (findItem.data) {
        const filtered = filterAndRemove(
          itemsSelected,
          "ped_venda_cod",
          ped_venda_cod
        );
        return setItemsSelected(filtered.data);
      }
      const items = [...itemsSelected, { ped_venda_cod: ped_venda_cod }];
      return setItemsSelected(items);
    }
    return setItemsSelected([{ ped_venda_cod: ped_venda_cod }]);
  };

  const SearchIsSelected = ({ ped_venda_cod }) => {
    const findItem = itemsSelected?.find(
      (item) => item.ped_venda_cod === ped_venda_cod
    );

    if ((findItem && !selectAll) || (!findItem && selectAll)) {
      return (
        <div
          className="btn btn-danger btn-sm mr-2"
          onClick={() => handleSelectItem(ped_venda_cod)}
        >
          <i className="fa fa-check-square"></i> Selecionado
        </div>
      );
    }
    if ((findItem && selectAll) || (!findItem && !selectAll)) {
      return (
        <div
          className="btn btn-danger btn-sm mr-2"
          onClick={() => handleSelectItem(ped_venda_cod)}
        >
          Selecionar
        </div>
      );
    }
  };
  return items && items.total > 0 ? (
    items.data.map((item, i) => (
      <div key={i}>
        <div className="d-flex flex-wrap justify-content-between align-center p-3">
          <div className="mr-2">{item.conc_nome}</div>
          <div className="row p-3">
            <>
              <span
                className="badge text-light text-center mr-2 p-2"
                style={{ backgroundColor: item.st_cor_valor }}
              >
                {item.st_desc}
              </span>
              {selectItem ? (
                <>
                  <SearchIsSelected ped_venda_cod={item.ped_venda_cod} />
                </>
              ) : (
                ""
              )}
              {loading || routine ? (
                <Spinner direction={"center"} label={"Carregando"} />
              ) : (
                <div
                  className={
                    selectItem ? "btn btn-dark btn-sm" : "btn btn-danger btn-sm"
                  }
                  onClick={() => generateDirectly(item.ped_venda_cod)}
                >
                  <i className="fa fa-bug"></i> Gerar relatório
                </div>
              )}
            </>
          </div>
          {item.rod_desc ? (
            <div className="text-secondary w-100">Rodovia {item.rod_desc}</div>
          ) : (
            ""
          )}
          <div className="w-100">
            {item.ped_venda_date ? (
              `Aberto dia ${item.ped_venda_date
                ? formateDate(`${item.ped_venda_date} 00:00:00`)
                : ""
              }`
            ) : (
              <>
                <i className="fa fa-wifi" aria-hidden="true"></i>...
              </>
            )}
          </div>
        </div>
        <div className="col-12 ml-2">
          Não Conformidade n° {item?.sisf_sinc_id || item.ped_venda_cod}     (N° rastreio {item.ped_venda_cod})
        </div>

        <div className="text-secondary col-12 ml-2">
          <span className="fw-bold mr-1">
            {item.lot_conc_desc ? "Lote" : ""}
            {item.cont_ncontrato ? "N° do Contrato" : ""}
          </span>
          {item.lot_conc_desc || item.cont_ncontrato}
        </div>
        <hr />
      </div>
    ))
  ) : (
    <div className="col-12 p-3 mb-3">{data?.message}</div>
  );
};
export default ListOversightReport;
