import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { namesScreens } from '../../../../constants'
const RenderSumTH = ({ items, viewMonitoring, scopeRowValue }) => {
	const location = useLocation()
	const [disabled, setDisabled] = useState(false)
	useEffect(() => {
		const controllDisabledInputs = () => {
			const { pathname } = location
			if (pathname === namesScreens.REPORT_CONSTRUCTION_PLAN) {
				return setDisabled(true)
			}
			return setDisabled(false)
		}
		controllDisabledInputs()
	}, [location])
	const [sum, setSum] = useState({
		pvs_qtd: 0,
		prog_serv_qtd_acul: 0,
		prog_serv_percen_progress: 0,
		prog_serv_percen_acul_ant: 0,
		prog_serv_percen_acul: 0,
	})
	const [state, setState] = useState(null)
	useEffect(() => {
		const calcSum = () => {
			if (!Array.isArray(items)) {
				return
			}
			if (!scopeRowValue) {
				const firstItems = items[0]
				setState({
					...firstItems
				})
			}
			if (viewMonitoring) {
				return
			}
			const pvs_qtd = items.reduce((previuesValues, currentValues) => previuesValues + currentValues.pvs_qtd, 0)
			const prog_serv_qtd_acul = items.reduce((previuesValues, currentValues) => previuesValues + currentValues.prog_serv_qtd_acul, 0)
			const prog_serv_percen_progress = items.reduce((previuesValues, currentValues) => previuesValues + currentValues.prog_serv_percen_progress, 0)
			const prog_serv_percen_acul_ant = items.reduce((previuesValues, currentValues) => previuesValues + currentValues.prog_serv_percen_acul_ant, 0)
			const prog_serv_percen_acul = items.reduce((previuesValues, currentValues) => previuesValues + currentValues.prog_serv_percen_acul, 0)
			setSum({
				pvs_qtd,
				prog_serv_qtd_acul,
				prog_serv_percen_progress,
				prog_serv_percen_acul_ant,
				prog_serv_percen_acul,
			})
		}
		calcSum()
	}, [items, scopeRowValue])
	return (
		<tr>
			<th scope="row" colSpan="3">
				{scopeRowValue || state?.serv_desc}
			</th>
			{!disabled ? (
				<td></td>
			) : ''}
			<td></td>
			<td>{sum.pvs_qtd > 0 ? `${parseFloat(sum.prog_serv_percen_progress).toFixed(2)}%` : ''}</td>
			<td>{sum.pvs_qtd > 0 ? `${parseFloat(sum.prog_serv_percen_acul_ant).toFixed(2)}%` : ''}</td>
			<td>{sum.pvs_qtd > 0 ? `${parseFloat(sum.prog_serv_percen_acul).toFixed(2)}%` : ''}</td>
			{!disabled ? (
				<td></td>
			) : ''}
		</tr>
	)
}
export default RenderSumTH