import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
import { authContext } from '../../../context/authContext'
const SearchChecList = ({ handleNew }) => {
	const { onChange, state: { desc } } = useContext(formContext)
	const { user } = useContext(authContext)
	const handleSearch = () => {

	}
	return (
		<div className="input-group input-group-sm">
			<div className="input-group-append">
				<button className="btn btn-tool btn-sm">
                    Atualizar lista
				</button>
			</div>
			{user?.set_nivel <= 1 && (
				<div className="input-group-append">
					<button className="btn btn-tool btn-sm" onClick={handleNew}>
						<i className="fas fa-plus"></i> Novo
					</button>
				</div>
			)}
			<input type="text" className="form-control float-right"
				name="desc" value={desc} onChange={onChange}
				placeholder="Digite aqui para pesquisar" />
			<div className="input-group-append">
				<button className="btn btn-default" onClick={handleSearch}>
					<i className="fas fa-search"></i>
				</button>
			</div>
		</div>
	)
}
export default SearchChecList