import { colorsToChardPorcenExec, config, labelsToChartPorcenExec } from '../../constants'
import { generateRandomColorPaletteWithBrightness } from '../arrayHandless'

const loadConfigOutros = async () => {
	const ane_pl_ob_tipo_outro = await config('ane_pl_ob_tipo_outro')
	if (!ane_pl_ob_tipo_outro) {
		return
	}
	const config_ane_pl_ob_tipo_outro = await ane_pl_ob_tipo_outro
	const { config_para_value } = config_ane_pl_ob_tipo_outro
	return Number(config_para_value)
}
const loadConfigGaleria = async () => {
	const ane_pl_ob_tipo_galeria = await config('ane_pl_ob_tipo_galeria')
	if (!ane_pl_ob_tipo_galeria) {
		return
	}
	const config_ane_pl_ob_tipo_galeria = await ane_pl_ob_tipo_galeria
	const { config_para_value } = config_ane_pl_ob_tipo_galeria

	return Number(config_para_value)
}
const loadConfigPvsStatusPedente = async () => {
	const ped_venda_status_pendente_aprovacao = await config('ped_venda_status_pendente_aprovacao')
	if (!ped_venda_status_pendente_aprovacao) {
		return
	}
	const config_ped_venda_status_pendente_aprovacao = await ped_venda_status_pendente_aprovacao
	const { config_para_value } = config_ped_venda_status_pendente_aprovacao
	return Number(config_para_value)
}
const criarDatasetCompletado = (dataset, todasAsDatas, key_por_cen, key_data) => {
	const datasetCompletado = todasAsDatas.map(data => {
		const item = dataset.find(i => i[key_data] === data)
		return item ? parseFloat(item[key_por_cen]).toFixed(2) : null
	})
	return datasetCompletado
}
const geneateDataChartPizzaPorCenExec = ({
	data, state
}) => {
	const { pl_ob_cod, sisf_sinc_int_amp_id, conc_nome } = state
	try {
		const pl_ob_reais_prev = data.reduce((previuesValues, currentValues) => previuesValues + (currentValues.pvs_qtd * currentValues.pvs_valor), 0)
		const accumulated = data.reduce((previuesValues, currentValues) => previuesValues + (currentValues.prog_serv_percen_acul_ant * currentValues.pvs_valor * 100 / pl_ob_reais_prev), 0)
		const progress_period = data.reduce((previuesValues, currentValues) => previuesValues + ((currentValues.prog_serv_qtd_acul * currentValues.pvs_valor * 100 / pl_ob_reais_prev) - (currentValues.prog_serv_percen_acul_ant * currentValues.pvs_valor * 100 / pl_ob_reais_prev)), 0)
		const will_be_executed = 100 - (accumulated + progress_period)
		const chartData = [accumulated, progress_period, will_be_executed]
		const backgroundColor = generateRandomColorPaletteWithBrightness(chartData.length)
		const labels = labelsToChartPorcenExec.map((item, i) => `${item} - ${parseFloat(chartData[i]).toFixed(2)}`)
		const option_legend = [{
			label: labels[0],
			value: chartData[0]
		},
		{
			label: labels[1],
			value: chartData[1]
		},
		{
			label: labels[2],
			value: chartData[2]
		}
		]
		return {
			labels,
			datasets: [
				{
					label: `Plano de obra n° ${sisf_sinc_int_amp_id || pl_ob_cod} | ${conc_nome}`,
					data: chartData.map(item => isNaN(item) ? 0 : Number(item)),
					backgroundColor: colorsToChardPorcenExec,
					borderColor: backgroundColor[1].brightness,
					borderWidth: 1,
				},
			],
			option_legend
		}
	} catch (error) {
		console.log(error)
		return null
	}
}
const generateItemsCalculedAcPr = ({ dataWithCodigo, pl_ob_reais_prev }) => {
	try {
		const sumCalcAcProg = dataWithCodigo.map(item => {
			let prog_serv_percen_progress,
				prog_serv_percen_acul_ant,
				prog_serv_percen_acul
			prog_serv_percen_progress = (item.prog_serv_qtd_acul * item.pvs_valor * 100 / pl_ob_reais_prev) - item.prog_serv_percen_acul_ant
			prog_serv_percen_acul = item.prog_serv_qtd_acul * item.pvs_valor * 100 / pl_ob_reais_prev
			prog_serv_percen_acul_ant = item.prog_serv_percen_acul_ant
			return {
				...item,
				prog_serv_percen_progress: isNaN(prog_serv_percen_progress) ? 0 : prog_serv_percen_progress,
				prog_serv_percen_acul: isNaN(prog_serv_percen_acul) ? 0 : prog_serv_percen_acul,
				prog_serv_percen_acul_ant: isNaN(prog_serv_percen_acul_ant) ? 0 : prog_serv_percen_acul_ant,
			}
		})
		return sumCalcAcProg
	} catch (error) {
		console.log(error)
		return []
	}
}
export { loadConfigGaleria, loadConfigOutros, loadConfigPvsStatusPedente, criarDatasetCompletado, geneateDataChartPizzaPorCenExec, generateItemsCalculedAcPr }