import React from "react";
import RenderTH from "./RenderTH";
const MonitoringTable = ({ items, isNew=false, handleDelete=null }) => {
    if (!Array.isArray(items)) {
        <div className="text-secondary">
            <p>Não há itens para serem exibidos</p>
        </div>
    }
    return (
        <div className="table-responsive">
            <table className="table table-bordered">
                <thead>
                    <tr>
                        {!isNew&&<th scope="col">Plano de Obra N°</th>}
                        <th scope="col">Mês Inicio</th>
                        <th scope="col">Mês Final</th>
                        <th scope="col">Previsto</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {items.sort((a, b) => a.acob_perc_prev - b.acob_perc_prev).map(item => (
                        <RenderTH item={item} key={item.acob_cod} isNew={isNew} handleDelete={handleDelete} />
                    ))}
                </tbody>
            </table>
        </div>
    )
}
export default MonitoringTable